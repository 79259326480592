// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-atualizacao-js": () => import("./../../../src/pages/atualizacao.js" /* webpackChunkName: "component---src-pages-atualizacao-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-n-js": () => import("./../../../src/pages/n.js" /* webpackChunkName: "component---src-pages-n-js" */),
  "component---src-pages-nff-js": () => import("./../../../src/pages/nff.js" /* webpackChunkName: "component---src-pages-nff-js" */),
  "component---src-pages-nfm-js": () => import("./../../../src/pages/nfm.js" /* webpackChunkName: "component---src-pages-nfm-js" */),
  "component---src-pages-pesquisa-js": () => import("./../../../src/pages/pesquisa.js" /* webpackChunkName: "component---src-pages-pesquisa-js" */),
  "component---src-templates-associe-se-js": () => import("./../../../src/templates/associe-se.js" /* webpackChunkName: "component---src-templates-associe-se-js" */),
  "component---src-templates-conselho-deliberativo-js": () => import("./../../../src/templates/conselho-deliberativo.js" /* webpackChunkName: "component---src-templates-conselho-deliberativo-js" */),
  "component---src-templates-custom-page-js": () => import("./../../../src/templates/custom-page.js" /* webpackChunkName: "component---src-templates-custom-page-js" */),
  "component---src-templates-diretoria-js": () => import("./../../../src/templates/diretoria.js" /* webpackChunkName: "component---src-templates-diretoria-js" */),
  "component---src-templates-galerias-index-js": () => import("./../../../src/templates/galerias/index.js" /* webpackChunkName: "component---src-templates-galerias-index-js" */),
  "component---src-templates-galleries-js": () => import("./../../../src/templates/galleries.js" /* webpackChunkName: "component---src-templates-galleries-js" */),
  "component---src-templates-idolos-js": () => import("./../../../src/templates/idolos.js" /* webpackChunkName: "component---src-templates-idolos-js" */),
  "component---src-templates-noticias-index-js": () => import("./../../../src/templates/noticias/index.js" /* webpackChunkName: "component---src-templates-noticias-index-js" */),
  "component---src-templates-noticias-js": () => import("./../../../src/templates/noticias.js" /* webpackChunkName: "component---src-templates-noticias-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-partidas-js": () => import("./../../../src/templates/partidas.js" /* webpackChunkName: "component---src-templates-partidas-js" */),
  "component---src-templates-player-js": () => import("./../../../src/templates/player.js" /* webpackChunkName: "component---src-templates-player-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-team-js": () => import("./../../../src/templates/team.js" /* webpackChunkName: "component---src-templates-team-js" */)
}

