import PropTypes from 'prop-types';

const extractNumberWithoutUnitMesure = (fontSize) =>
  Number.parseFloat(fontSize.match(/\d\.\d|\d+/g)[0]);
const extractUnitOfMeasurementWithoutNumber = (fontSize) =>
  fontSize.match(/[a-zA-Z]+/g)[0];
const calculateLineHeight = (number) => number * 1.1;
const calculateLetterSpacing = (number) => number * 0.12;
const calculateWordSpacing = (number) => number * 0.16;

// Source: https://www.w3.org/TR/WCAG21/#text-spacing
export const textSpacingStyle = (fontSize) => {
  const number = extractNumberWithoutUnitMesure(fontSize);
  const unit = extractUnitOfMeasurementWithoutNumber(fontSize);
  const lineHeight = `${calculateLineHeight(number)}${unit}`;
  const letterSpacing = `${calculateLetterSpacing(number)}${unit}`;
  const wordSpacing = `${calculateWordSpacing(number)}${unit}`;
  return {
    wordSpacing,
    letterSpacing,
    lineHeight,
  };
};

export const reduceTextSize = (fontSize, porcentage = 100) => {
  const number = extractNumberWithoutUnitMesure(fontSize);
  const unit = extractUnitOfMeasurementWithoutNumber(fontSize);
  const newFontSize = `${number * (porcentage / 100)}${unit}`;
  return {
    fontSize: newFontSize,
  };
};

export const a11yButtonProps = {
  ariaLabel: PropTypes.string.isRequired,
  ariaLabelledby: PropTypes.string,
  ariaPressed: PropTypes.oneOf([true, false, 'mixed']),
};

export const testProps = {
  dataTestID: PropTypes.string.isRequired,
};
