/* eslint-disable no-shadow */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useFirebase } from 'gatsby-plugin-firebase';
import styled, { ThemeProvider } from 'styled-components';
import CookieConsent from 'react-cookie-consent';
import GlobalStyle from '../theme/global';
import theme from '../theme';
import SEO from '../components/seo';
import FullscreenModal from '../components/FullscreenModal';
import Page from './Page';

export default function Layout(props) {
  const { children } = props;

  useFirebase((firebase) => {
    firebase.analytics();
  });

  // useEffect(() => {
  //   if (window) {
  //     (function _(a, b, c, d, e) {
  //       let f = window.console;
  //       f
  //         && Math.floor(new Date().getTime() / 1e3) - b > 7 * 24 * 60 * 60
  //         && f.warn('The Facebook JSSDK is more than 7 days old.');
  //       if (window[c]) return;
  //       if (!window.JSON) return;
  //       var g = (window[c] = {
  //         __buffer: {
  //           replay() {
  //             const a = this;
  //             const b = function (d) {
  //               let b = window[c];
  //               a.calls[d][0].split('.').forEach((a) => (b = b[a]));
  //               b.apply(null, a.calls[d][1]);
  //             };
  //             for (let d = 0; d < this.calls.length; d++) b(d);
  //             this.calls = [];
  //           },
  //           calls: [],
  //           opts: null,
  //         },
  //         getUserID() {
  //           return '';
  //         },
  //         getAuthResponse() {
  //           return null;
  //         },
  //         getAccessToken() {
  //           return null;
  //         },
  //         init(a) {
  //           g.__buffer.opts = a;
  //         },
  //       });
  //       for (var b = 0; b < d.length; b++) {
  //         f = d[b];
  //         if (f in g) continue;
  //         var h = f.split('.');
  //         var i = h.pop();
  //         var j = g;
  //         for (var k = 0; k < h.length; k++) j = j[h[k]] || (j[h[k]] = {});
  //         j[i] = (function (a) {
  //           if (a === 'init') return;
  //           return function () {
  //             g.__buffer.calls.push([a, Array.prototype.slice.call(arguments)]);
  //           };
  //         }(f));
  //       }
  //       k = a;
  //       h = /Chrome\/(\d+)/.exec(navigator.userAgent);
  //       h
  //         && Number(h[1]) >= 55
  //         && 'assign' in Object
  //         && 'findIndex' in []
  //         && (k += '&ua=modern_es6');
  //       j = document.createElement('script');
  //       j.src = k;
  //       j.async = !0;
  //       e && (j.crossOrigin = 'anonymous');
  //       i = document.getElementsByTagName('script')[0];
  //       i.parentNode && i.parentNode.insertBefore(j, i);
  //     }(
  //       'https://connect.facebook.net/en_US/sdk.js?hash=1711be315f1c65174842b928d88acd08',
  //       1590452389,
  //       'FB',
  //       [
  //         'AppEvents.EventNames',
  //         'AppEvents.ParameterNames',
  //         'AppEvents.activateApp',
  //         'AppEvents.clearAppVersion',
  //         'AppEvents.clearUserID',
  //         'AppEvents.getAppVersion',
  //         'AppEvents.getUserID',
  //         'AppEvents.logEvent',
  //         'AppEvents.logPageView',
  //         'AppEvents.logPurchase',
  //         'AppEvents.setAppVersion',
  //         'AppEvents.setUserID',
  //         'AppEvents.updateUserProperties',
  //         'Canvas.Plugin.showPluginElement',
  //         'Canvas.Plugin.hidePluginElement',
  //         'Canvas.Prefetcher.addStaticResource',
  //         'Canvas.Prefetcher.setCollectionMode',
  //         'Canvas.getPageInfo',
  //         'Canvas.scrollTo',
  //         'Canvas.setAutoGrow',
  //         'Canvas.setDoneLoading',
  //         'Canvas.setSize',
  //         'Canvas.setUrlHandler',
  //         'Canvas.startTimer',
  //         'Canvas.stopTimer',
  //         'Event.subscribe',
  //         'Event.unsubscribe',
  //         'XFBML.parse',
  //         'addFriend',
  //         'api',
  //         'getAccessToken',
  //         'getAuthResponse',
  //         'getLoginStatus',
  //         'getUserID',
  //         'init',
  //         'login',
  //         'logout',
  //         'publish',
  //         'share',
  //         'ui',
  //       ],
  //       true,
  //     ));
  //   }
  // });
  return (
    <ThemeProvider theme={theme}>
      <SEO title="Sport Club Internacional" />
      <GlobalStyle />
      <Page>{children}</Page>
      {/* <CookieConsent
        location="bottom"
        buttonText="Okay"
        cookieName="gatsby-gdpr-google-analytics"
        style={{ zIndex: 1000000 }}
      >
        Esse site usa cookies para analisar o tráfego
      </CookieConsent> */}
    </ThemeProvider>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};
