import React from 'react';
import { graphql, StaticQuery } from 'gatsby';

export default function withSponsors(Component) {
  const query = graphql`
    query {
      wpgraphql {
        generalConfigs(where: { name: "sponsors" }) {
          nodes {
            slug
            sponsors {
              masterSponsors {
                link
                name
                logo {
                  sourceUrl
                }
              }
              partners {
                link
                name
                logo {
                  sourceUrl
                }
              }
              sponsors {
                link
                name
                logo {
                  sourceUrl
                }
              }
            }
          }
        }
      }
    }
  `;

  const render = (data) => {
    const {
      wpgraphql: {
        generalConfigs: { nodes },
      },
    } = data;
    const {
      sponsors: { partners, masterSponsors, sponsors },
    } = nodes[0];

    return (
      <Component
        partners={partners}
        master={masterSponsors}
        sponsors={sponsors}
      />
    );
  };

  return function HOC() {
    return <StaticQuery query={query} render={render} />;
  };
}
