import { login, checkTokenValidity, connect } from './auth.service';

export const init = (endpoint) => connect(endpoint);

export async function signIn({ username, password }) {
  const { data: loginResponse } = await login(username, password);

  localStorage.setItem('sci-partner', JSON.stringify(loginResponse.data));
  return loginResponse.data;
}

export function signOut() {
  localStorage.removeItem('sci-partner');
}

export async function isAuthenticatedUser(token) {
  try {
    await checkTokenValidity(token);
    return true;
  } catch (error) {
    localStorage.removeItem('sci-partner');
    throw error;
  }
}
