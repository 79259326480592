import React from 'react';
import styled from 'styled-components';
import Sponsors from './sponsors';
import SocialNetworks from './social-networks';
import Map from './map';

const Container = styled.footer`
  width: 100%;
  display: grid;
  grid-auto-flow: row;
  overflow-x: hidden;
`;

const Signature = styled.section`
  background: ${({ theme }) => theme.colors.secondary_dark};
  color: ${({ theme }) => theme.colors.secondary};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem 0 1.5rem;

  hr {
    width: 90%;
    opacity: 0.2;
    margin-top: 2rem;
  }

  & > div {
    width: 90%;
    display: flex;
    justify-content: space-between;
    max-width: ${({ theme }) => theme.dimensions.desktop.main};

    & > div {
      p {
        margin: 0;
      }
    }

    a {
      margin-right: 100px;
      align-self: end;
      text-transform: uppercase;
      letter-spacing: 0.5em;
    }
  }
`;
export default function Footer() {
  return (
    <Container>
      <Sponsors />
      <SocialNetworks />
      <Map />
      <Signature>
        <hr />
        <div>
          <div>
            <p>
              <strong>Sport Club Internacional</strong>
            </p>
            <p>Av. Padre Cacique, 891 - CEP 90810-240 - Porto Alegre - RS</p>
            <p>(51) 3230-4600</p>
          </div>
          <a href="https://groundsportech.com/">Ground</a>
        </div>
      </Signature>
    </Container>
  );
}
