import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Facebook from './Facebook';
import Flickr from './Flickr';
import Instagram from './Instagram';
import Soundcloud from './Soundcloud';
import Spotify from './Spotify';
import Twitter from './Twitter';
import Youtube from './Youtube';
import OneFootball from './OneFootball';

import { getSocialNetworkURL } from './helpers';

const Container = styled.div`
  display: flex;
  svg {
    display: block;
    margin: auto;
  }
`;

const Spacer = styled.span`
  margin: 0 0.1rem;
`;

export default function SocialLinks(props) {
  const { networks, color, background, size } = props;
  return (
    <Container>
      {!!networks.facebook && (
        <>
          <Facebook
            url={getSocialNetworkURL('facebook', networks.facebook)}
            color={color}
            background={background}
            size={size}
          />
          <Spacer />
        </>
      )}
      {!!networks.flickr && (
        <>
          <Flickr
            url={getSocialNetworkURL('flickr', networks.flickr)}
            color={color}
            background={background}
            size={size}
          />
          <Spacer />
        </>
      )}
      {!!networks.instagram && (
        <>
          <Instagram
            url={getSocialNetworkURL('instagram', networks.instagram)}
            color={color}
            background={background}
            size={size}
          />
          <Spacer />
        </>
      )}
      {!!networks.soundcloud && (
        <>
          <Soundcloud
            url={getSocialNetworkURL('soundcloud', networks.soundcloud)}
            color={color}
            background={background}
            size={size}
          />
          <Spacer />
        </>
      )}
      {!!networks.spotify && (
        <>
          <Spotify
            url={getSocialNetworkURL('spotify', networks.spotify)}
            color={color}
            background={background}
            size={size}
          />
          <Spacer />
        </>
      )}
      {!!networks.twitter && (
        <>
          <Twitter
            url={getSocialNetworkURL('twitter', networks.twitter)}
            color={color}
            background={background}
            size={size}
          />
          <Spacer />
        </>
      )}
      {!!networks.youtube && (
        <>
          <Youtube
            url={getSocialNetworkURL('youtube', networks.youtube)}
            color={color}
            background={background}
            size={size}
          />
          <Spacer />
        </>
      )}
      {!!networks.onefootball && (
        <>
          <OneFootball
            url={getSocialNetworkURL('onefootball', networks.onefootball)}
            color={color}
            background={background}
            size={size}
          />
          <Spacer />
        </>
      )}
    </Container>
  );
}

SocialLinks.propTypes = {
  networks: PropTypes.shape({
    facebook: PropTypes.string,
    flickr: PropTypes.string,
    instagram: PropTypes.string,
    soundcloud: PropTypes.string,
    spotify: PropTypes.string,
    twitter: PropTypes.string,
    youtube: PropTypes.string,
  }).isRequired,
  color: PropTypes.string,
  background: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
};
