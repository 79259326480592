import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import sitemap from '../sitemap';

const SiteMap = styled.section`
  background: ${({ theme }) => theme.colors.secondary_dark};
  color: ${({ theme }) => theme.colors.secondary_light};
  padding: 2rem 1rem;
  display: flex;
  & > ul {
    min-width: ${({ theme }) => theme.dimensions.mobile.min};
    max-width: ${({ theme }) => theme.dimensions.desktop.main};
    display: grid;
    grid-template-areas: 'li li li';
    gap: 40px 20px;
    justify-content: space-between;
    & > li {
      & > span,
      & > a {
        font-weight: 700;
        text-transform: uppercase;
        text-align: center;
      }

      & > a {
        color: ${({ theme }) => theme.colors.white};
      }

      &:last-child {
        justify-self: center;
      }

      & > ul {
        margin-top: 0.1rem;
        padding-top: 1rem;
        border-top: 1px solid ${({ theme }) => theme.colors.secondary_light};
        display: grid;
        gap: 10px 20px;
        & > li > span,
        & > li > a {
          font-weight: 700;
        }

        & > li > a {
          color: ${({ theme }) => theme.colors.white};
        }

        @media (max-width: ${({ theme }) => theme.dimensions.mobile.med}) {
          & {
            gap: 5px 0;
          }
        }
      }

      &:nth-child(2) > ul,
      &:nth-child(3) > ul {
        grid-template-areas: 'li li li';
      }

      @media (max-width: ${({ theme }) => theme.dimensions.mobile.med}) {
        &:nth-child(2) > ul,
        &:nth-child(3) > ul {
          padding-top: 15px;
        }

        &:nth-child(2) > ul {
          grid-template-areas: 'li';
        }

        &:nth-child(3) > ul {
          grid-template-areas: 'li li';
        }
      }

      @media (min-width: ${({ theme }) =>
          theme.dimensions.mobile.med}) and (max-width: ${({ theme }) =>
          theme.dimensions.mobile.max}) {
        &:nth-child(2) > ul {
          grid-template-areas: 'li li li';
        }
      }
    }

    @media (max-width: ${({ theme }) => theme.dimensions.mobile.med}) {
      & {
        grid-template-areas: 'li';
        padding: 10px;
      }
    }

    @media (min-width: ${({ theme }) =>
        theme.dimensions.mobile.med}) and (max-width: ${({ theme }) =>
        theme.dimensions.mobile.max}) {
      & {
        grid-template-areas: 'li li';
      }
    }
  }

  & a:hover {
    color: ${({ theme }) => theme.colors.primary};
  }

  @media (max-width: ${({ theme }) => theme.dimensions.mobile.med}) {
    & {
      padding: 0;
    }
  }

  ul {
    margin: 10px 0;
  }

  @media (min-width: ${({ theme }) => theme.dimensions.mobile.max}) {
    justify-content: center;
    align-items: center;
  }
`;

function renderSiteMap(map) {
  const keys = Object.keys(map);
  return (
    <ul>
      {keys.map((key) => {
        const item = map[key];
        let label = <span>{item.label}</span>;

        if (item.to) {
          label = <Link to={item.to}>{label}</Link>;
        }
        if (item.link) {
          label = (
            <a href={item.link} target="_blank">
              {label}
            </a>
          );
        }

        return (
          <li key={key}>
            {label}
            {item.menu ? renderSiteMap(item.menu) : ''}
          </li>
        );
      })}
    </ul>
  );
}

export default function Map() {
  return <SiteMap>{renderSiteMap(sitemap)}</SiteMap>;
}
