export default {
  primary: '#e30513',
  primary_dark: '#b61918',
  primary_light: '#dd3330',
  secondary: '#7b7b7a',
  secondary_dark: '#575756',
  secondary_light: '#e4e4e4',
  highlight: '#f7b500',
  text_color: '#232323',
  text_on_primary: '#ffffff',
  text_on_primary_dark: '#ffffff',
  text_on_primary_light: '#ffffff',
  text_on_secondary: '#7b7b7a',
  text_on_secondary_dark: '#9d9d9d',
  text_on_secondary_light: '#dcdcdc',
  text_on_highlight: '#232323',
  red: '#e30513',
  black: '#232323',
  gray: '#7b7b7a',
  white: '#ffffff',
};
