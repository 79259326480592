import React, { useEffect, useState } from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import ConditionalRenderer from '../components/conditionalRenderer';
import GoBack from '../components/go-back';

const sitemap = {
  1: {
    label: 'Notícias',
    inline: true,
    menu: {
      1: {
        label: 'Últimas',
        to: '/n',
      },
      2: {
        label: 'Galerias de Fotos',
        to: '/galerias',
      },
      3: {
        label: 'Gestão',
        to: '/noticias-gestao',
      },
      4: {
        label: 'Futebol Masculino',
        to: '/nfm',
      },
      5: {
        label: 'Futebol Feminino',
        to: '/nff',
      },
      6: {
        label: 'Celeiro de Ases',
        to: '/noticias-celeiro-de-ases',
      },
      7: {
        label: 'Relacionamento Social',
        to: '/noticias-relacionamento-social',
      },
      8: {
        label: 'Conselho Deliberativo',
        to: '/noticias-conselho-deliberativo',
      },
      9: {
        label: 'FECI',
        to: '/noticias-feci',
      },
    },
  },
  2: {
    label: 'Associe-se',
    link: '/associe-se',
  },
  3: {
    label: 'Clube',
    menu: {
      1: {
        label: 'História',
        menu: {
          1: {
            label: 'História do Clube',
            to: '/historia/historia',
          },
          2: {
            label: 'Títulos',
            to: '/historia/titulos',
          },
          3: {
            label: 'Ídolos',
            to: '/idolos',
          },
          4: {
            label: 'Mais sobre o Inter',
            to: '/mais-sobre-o-inter',
          },
          5: {
            label: 'Arquivo Histórico',
            link: 'http://memoriadointer.blogspot.com/',
          },
        },
      },
      2: {
        label: 'Beira-Rio',
        menu: {
          1: {
            label: 'Construção',
            to: '/beira-rio/construcao',
          },
          2: {
            label: 'Inauguração',
            to: '/beira-rio/inauguracao',
          },
          3: {
            label: 'Templo Sagrado',
            to: '/beira-rio/templo-sagrado',
          },
          4: {
            label: 'Gigante Para Sempre',
            to: '/beira-rio/gigante-para-sempre',
          },
          5: {
            label: 'Copa 2014',
            to: '/beira-rio/copa-2014',
          },
        },
      },
      3: {
        label: 'Consulados',
        menu: {
          1: {
            label: 'Notícias consulares',
            to: '/noticias-consulados',
          },
          2: {
            label: 'Funções do consulado',
            to: '/consulados/funcoes',
          },
          // 3: {
          //   label: 'Monte seu evento',
          //   to: '/consulados/monte-seu-evento',
          // },
          4: {
            label: 'Traga sua faixa',
            to: '/consulados/traga-sua-faixa',
          },
          5: {
            label: 'Cônsules culturais',
            to: '/consulados/consules-culturais',
          },
        },
      },
      4: {
        label: 'Diretoria',
        to: '/diretoria',
      },
      5: {
        label: 'Patrimônio',
        to: '/patrimonio',
      },
      6: {
        label: 'Conselho Deliberativo',
        to: '/conselho-deliberativo',
      },
      7: {
        label: 'Conselho Fiscal',
        to: '/conselho-fiscal',
      },
      8: {
        label: 'Portal da Transparência',
        link: 'http://transparencia.internacional.com.br/',
      },
      9: {
        label: 'Estatuto',
        to: '/instrumentos-normativos-e-publicacoes-legais',
      },
      10: {
        label: 'FECI',
        to: '/feci',
      },
      11: {
        label: 'Trabalhe conosco',
        to: 'https://internacional.pandape.infojobs.com.br/',
      },
    },
  },
  4: {
    label: 'Futebol',
    menu: {
      1: {
        label: 'Masculino',
        menu: {
          1: {
            label: 'Plantel',
            to: '/futebol/futebol-masculino',
          },
          2: {
            label: 'Jogos',
            to: '/partidas',
          },
          3: {
            label: 'Horários de Treino',
            to: '/horario-de-treinos-masculino',
          },
          4: {
            label: 'Notícias',
            to: '/nfm',
          },
        },
      },
      2: {
        label: 'Feminino',
        menu: {
          1: {
            label: 'Plantel',
            to: '/futebol/futebol-feminino',
          },
          2: {
            label: 'Jogos',
            to: '/gurias-coloradas-jogos',
          },
          // 3: {
          //   label: 'Horários de Treino',
          //   to: '/horario-de-treinos-feminino',
          // },
          4: {
            label: 'Gurias Coloradas',
            to: '/organizacao-feminino',
          },
          5: {
            label: 'Notícias',
            to: '/nff',
          },
        },
      },
      3: {
        label: 'Celeiro de Ases',
        menu: {
          1: {
            label: 'Plantel Júnior',
            to: '/futebol/juniores',
          },
          2: {
            label: 'Jogos',
            to: '/juniores-jogos',
          },
          3: {
            label: 'Plantel Juvenil',
            to: '/futebol/juvenil',
          },
          4: {
            label: 'Jogos',
            to: '/juvenis-jogos',
          },
          5: {
            label: 'Organização',
            to: '/organizacao-base',
          },
          6: {
            label: 'Notícias',
            to: '/noticias-celeiro-de-ases',
          },
        },
      },
      4: {
        label: 'Escola Rubra',
        to: '/escola-rubra',
      },
      5: {
        label: 'Avaliações Técnicas',
        to: '/avaliacoes-tecnicas',
      },
    },
  },
  5: {
    label: 'Serviços',
    inline: true,
    highlights: {},
    menu: {
      1: {
        label: 'Ingressos',
        link: 'https://socio.internacional.com.br/',
        highlighted: true,
      },
      2: {
        label: 'Check-In',
        link: 'https://socio.internacional.com.br',
        highlighted: true,
      },
      3: {
        label: 'CAS',
        to: '/servicos/cas',
      },
      4: {
        label: 'Museu e Visita Colorada',
        to: '/servicos/museu',
      },
      5: {
        label: 'Estacionamento',
        to: '/servicos/estacionamento',
      },
      6: {
        label: 'Criança Colorada',
        to: '/servicos/crianca-colorada',
      },
      7: {
        label: 'Ouvidoria',
        to: '/servicos/ouvidoria',
      },
      // 8: {
      //   label: 'Central de Ajuda',
      //   link: 'https://internacional.zendesk.com/hc/pt-br',
      // },
      8: {
        label: 'Parque Gigante',
        to: '/patrimonio/parque-gigante',
      },
    },
  },
  // 5: {
  //   label: 'Clube de Vantagens',
  //   inline: true,
  //   menu: {
  //     1: {
  //       label: 'Faça parte',
  //       to: '/clube-vantagens',
  //       highlighted: true,
  //     },
  //     2: {
  //       label: 'Parceiros',
  //       link: 'https://internacional.programadevantagens.com.br/Lojas',
  //     },
  //     3: {
  //       label: 'Cupons',
  //       link: 'https://internacional.programadevantagens.com.br/Cupons',
  //     },
  //   },
  // },
  6: {
    label: 'Inter Store',
    link: 'https://www.lojadointer.com.br/',
  },
};

export default sitemap;

const menuIndexes = Object.keys(sitemap).filter((index) => sitemap[index].menu);

export function renderNav(handleHover) {
  return Object.keys(sitemap).map((index) => {
    const item = sitemap[index];

    if (item.to) {
      return (
        <Link to={item.to} key={index} onMouseEnter={() => handleHover(null)}>
          {item.label}
        </Link>
      );
    }
    if (item.link) {
      return (
        <a
          href={item.link}
          target="_blank"
          rel="noopener noreferrer"
          key={index}
          onMouseEnter={() => handleHover(null)}
        >
          {item.label}
        </a>
      );
    }
    if (item.menu) {
      return (
        <span
          onMouseEnter={() => handleHover(menuIndexes.indexOf(index))}
          key={index}
        >
          {item.label}
        </span>
      );
    }
  });
}

export function renderSubmenu(submenu, i, handleClick) {
  return (
    <li key={i}>
      <h3>{submenu.label}</h3>
      <ul>
        {Object.keys(submenu.menu).map((key) => {
          const data = submenu.menu[key];

          if (data.to) {
            return (
              <li key={key} onClick={handleClick}>
                <Link to={data.to}>
                  <h4>{data.label}</h4>
                </Link>
              </li>
            );
          }
          if (data.link) {
            return (
              <li key={key} onClick={handleClick}>
                <a href={data.link} target="_blank" rel="noopener noreferrer">
                  <h4>{data.label}</h4>
                </a>
              </li>
            );
          }
        })}
      </ul>
    </li>
  );
}

const MenuSlide = styled.div`
  padding: 1rem;
  box-sizing: border-box;

  * {
    font-size: 1rem;
    line-height: 1.2em;
    padding: 0;
    margin: 0;
  }

  h3 {
    font-weight: 700;
    text-transform: uppercase;
    line-height: 1.5em;
  }

  h4 {
    font-weight: 400;
  }

  & > ul {
    display: flex;
    justify-content: space-evenly;

    @media (max-width: ${({ theme }) => theme.dimensions.mobile.med}) {
      flex-wrap: wrap;
    }

    & > .menu-title {
      margin-right: 2rem;
      font-size: 2rem;
      text-transform: uppercase;
      font-weight: 700;
      line-height: 1.5em;
      flex-basis: 30%;
      flex-grow: 0;
      flex-shrink: 1;
      white-space: nowrap;
    }

    & > li {
      display: flex;
      flex-direction: column;
      flex-grow: 1;

      @media (max-width: ${({ theme }) => theme.dimensions.mobile.med}) {
        flex-shrink: 0;
        flex-basis: 300px;
      }
    }
  }

  & > ul > li > h3,
  & > ul > li > ul > li {
    padding: 0.5rem 0;

    &.menu-highlight {
      font-size: 1rem;
      font-weight: 700;
      line-height: 1.5em;
      background-color: ${({ theme }) => theme.colors.primary_dark};
      box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
      color: ${({ theme }) => theme.colors.text_on_primary_dark};
      text-transform: uppercase;
      border-radius: 2px;
      padding: 0 1rem;
      min-height: 2rem;
      min-width: 4rem;
      display: flex;
      justify-content: center;
      align-content: center;
      white-space: nowrap;

      & > a {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
      }
    }
  }

  ul.menu-highlights {
    display: flex;
    align-items: center;
    margin: 1rem 0;
    padding-bottom: 1.5rem;
    border-bottom: 2px solid ${({ theme }) => theme.colors.secondary_dark};

    & > li {
      flex-grow: 1;
      max-width: 50%;
      margin-left: 1rem;

      &:first-child {
        margin-left: 0;
      }
    }
  }
  ul.menu-inline {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;

    & > li {
      flex-basis: 33%;
      flex-grow: 1;
    }
  }
`;

export function renderMenu(menu, i, inline, title, handleClick) {
  const withSubmenu = [];
  const withoutSubmenu = [];
  const highlights = [];

  if (!menu) {
    return <></>;
  }

  Object.keys(menu).forEach((key) => {
    if (menu[key].menu) {
      return withSubmenu.push(menu[key]);
    }
    if (menu[key].highlighted) {
      return highlights.push(menu[key]);
    }
    return withoutSubmenu.push(menu[key]);
  });

  return (
    <MenuSlide key={i}>
      <ul>
        <ConditionalRenderer
          condition={title}
          renderOnSuccess={() => <li className="menu-title">{title}</li>}
        />
        <ConditionalRenderer
          condition={withSubmenu.length > 0}
          renderOnSuccess={() =>
            withSubmenu.map((item, index) =>
              renderSubmenu(item, index, handleClick),
            )
          }
        />
        <li>
          <ConditionalRenderer
            condition={highlights.length > 0}
            renderOnSuccess={() => (
              <ul className="menu-highlights">
                {highlights.map((data, index) => {
                  if (data.to) {
                    return (
                      <li
                        className="menu-highlight"
                        key={index}
                        onClick={handleClick}
                      >
                        <Link to={data.to}>
                          <h3>{data.label}</h3>
                        </Link>
                      </li>
                    );
                  }
                  if (data.link) {
                    return (
                      <li
                        className="menu-highlight"
                        key={index}
                        onClick={handleClick}
                      >
                        <a
                          href={data.link}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <h3>{data.label}</h3>
                        </a>
                      </li>
                    );
                  }
                  return (
                    <li className="menu-highlight" key={index}>
                      <h3>{data.label}</h3>
                    </li>
                  );
                })}
              </ul>
            )}
          />
          <ul className={inline ? 'menu-inline' : ''}>
            {withoutSubmenu.map((data, index) => {
              if (data.to) {
                return (
                  <li key={index} onClick={handleClick}>
                    <Link to={data.to}>
                      <h3>{data.label}</h3>
                    </Link>
                  </li>
                );
              }
              if (data.link) {
                return (
                  <li key={index} onClick={handleClick}>
                    <a
                      href={data.link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <h3>{data.label}</h3>
                    </a>
                  </li>
                );
              }
              return (
                <li key={index}>
                  <h3>{data.label}</h3>
                </li>
              );
            })}
          </ul>
        </li>
      </ul>
    </MenuSlide>
  );
}

const MobileMenu = styled.ul`
  position: relative;
  margin: 0;
  padding: 0;
  cursor: default;

  & > li {
    font-size: 1.5rem;
    line-height: 2em;
    text-transform: uppercase;
    font-weight: 700;
    text-align: left;
    display: flex;
    justify-content: space-between;
    align-items: center;

    a {
      width: 100%;
    }
  }
`;

const MobileSubmenu = styled.ul`
  position: fixed;
  background: #000000;
  transition: 0.6s;
  top: 70px;
  right: ${({ showSubmenu }) => (showSubmenu ? '0' : '-100%')};
  z-index: 10;
  width: 100%;
  margin: 0;
  padding: 0;
  height: calc(100vh - 70px);
  overflow-y: scroll;

  * {
    color: ${({ theme }) => theme.colors.white};
  }

  &::after {
    position: fixed;
    width: 100%;
    height: 2rem;
    bottom: 0;
    content: '';
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgb(0, 0, 0, 1) 100%
    );
  }
`;

export function MobileRenderer(props) {
  const { handleClick, getMenu } = props;
  const [submenu, setSubmenu] = useState(false);

  useEffect(() => {
    if (typeof getMenu === 'function') {
      getMenu({
        reset: () => setSubmenu(false),
      });
    }
  }, [submenu]);

  const onClickHandler = () => {
    setSubmenu(false);
    handleClick();
  };

  return (
    <MobileMenu>
      {Object.keys(sitemap).map((index) => {
        const item = sitemap[index];

        if (item.to) {
          return (
            <li key={index} onClick={onClickHandler}>
              <Link to={item.to}>{item.label}</Link>
            </li>
          );
        }
        if (item.link) {
          return (
            <li key={index} onClick={onClickHandler}>
              <a href={item.link} target="_blank" rel="noopener noreferrer">
                {item.label}
              </a>
            </li>
          );
        }
        if (item.menu) {
          return (
            <li key={index} onClick={() => setSubmenu(index)}>
              {item.label}
              <FontAwesomeIcon icon={faArrowRight} width="0" />
            </li>
          );
        }
      })}
      <MobileSubmenu showSubmenu={!!submenu}>
        {submenu && (
          <>
            <GoBack handleClick={() => setSubmenu(false)} />
            {renderMenu(
              sitemap[submenu].menu,
              submenu,
              false,
              '',
              onClickHandler,
            )}
          </>
        )}
      </MobileSubmenu>
    </MobileMenu>
  );
}
