import PropTypes from 'prop-types';
import styled from 'styled-components';
import { a11yButtonProps, testProps } from './helpers/accessibility';

function getIconSize(size) {
  switch (size) {
    case 'large':
      return '64px';
    case 'medium':
      return '32px';
    case 'small':
    default:
      return '24px';
  }
}

const CircularButton = styled.button.attrs((props) => ({
  'aria-label': props.ariaLabel,
  'data-test-id': props.dataTestID,
  'aria-labelledby': props.ariaLabelledby,
  'aria-pressed': props.ariaPressed,
}))`
  border: none;
  background: ${(props) => props.background};
  color: ${(props) => props.color};
  padding: 0;
  width: ${(props) => getIconSize(props.size)};
  height: ${(props) => getIconSize(props.size)};
  overflow: hidden;
  border-radius: 50%;
  font-size: calc(${(props) => getIconSize(props.size)} * 0.6);
  cursor: pointer;

  @media screen and (max-width: ${(props) =>
      props.theme.dimensions.mobile.max}) {
    && {
      width: ${getIconSize('medium')};
      height: ${getIconSize('medium')};
      font-size: calc(${getIconSize('medium')} * 0.6);
    }
  }
`;

CircularButton.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.string,
    PropTypes.element,
  ]),
  type: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  color: PropTypes.string,
  background: PropTypes.string,
  ...a11yButtonProps,
  ...testProps,
};

CircularButton.defaultProps = {
  type: 'button',
  size: 'medium',
  color: '#fff',
  background: '#000',
};

export default CircularButton;
