import React from 'react';
import styled from 'styled-components';
import withSponsors from '../../data/sponsors';

const SponsorsSection = styled.section`
  background: #cccccc;
  color: ${({ theme }) => theme.colors.secondary};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem 0;

  h3 {
    text-transform: uppercase;
    font-size: 1rem;
    font-weight: 700;
    margin: 0 0 2rem;
    opacity: 0.8;
  }

  ul {
    margin: 0;
    padding: 0;
    width: 100%;
  }

  hr {
    width: 90%;
    opacity: 0.2;
    margin-top: 2rem;
  }
`;

const SponsorsList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  list-style: none;

  * {
    margin: 0;
    padding: 0;
  }

  li {
    width: ${({ theme }) => theme.dimensions.mobile.min};
    display: flex;
    align-items: center;
    margin: 1rem 0;
  }

  figure {
    max-height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;

    img {
      max-height: 100%;
      max-width: 100%;

      &:hover {
        opacity: 0.8;
      }
    }
  }

  a {
    width: 100%;
  }

  .master {
    height: 125px;
  }

  .sponsor {
    height: 75px;
  }

  .partner {
    height: 50px;
    width: 160px;
  }
`;

const SponsorsRenderer = (props) => {
  const { master, sponsors, partners } = props;

  return (
    <SponsorsSection>
      <h3 className="section__title">Patrocinadores</h3>
      <SponsorsList>
        {master.map((sponsor, index) => (
          <li key={index} className="master">
            <a href={sponsor.link}>
              <figure>
                <img src={sponsor.logo.sourceUrl} alt={sponsor.name} />
              </figure>
            </a>
          </li>
        ))}
      </SponsorsList>
      <SponsorsList>
        {sponsors.map((sponsor, index) => (
          <li key={index} className="sponsor">
            <a href={sponsor.link}>
              <figure>
                <img src={sponsor.logo.sourceUrl} alt={sponsor.name} />
              </figure>
            </a>
          </li>
        ))}
      </SponsorsList>
      <hr />
      <h3>Parceiros</h3>
      <SponsorsList>
        {partners.map((sponsor, index) => (
          <li key={index} className="partner">
            <a href={sponsor.link}>
              <figure>
                <img src={sponsor.logo.sourceUrl} alt={sponsor.name} />
              </figure>
            </a>
          </li>
        ))}
      </SponsorsList>
    </SponsorsSection>
  );
};

export default withSponsors(SponsorsRenderer);
