/* eslint-disable jsx-a11y/media-has-caption */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlay,
  faPause,
  faVolumeUp,
  faVolumeDown,
  faVolumeOff,
  faVolumeMute,
} from '@fortawesome/free-solid-svg-icons';
import theme from '../theme';

const Button = styled.button`
  border: none;
  height: ${(props) => props.size};
  width: ${(props) => props.size};
  border-radius: 50%;
  transition: background-color 0.25s ease-in-out;
  background-color: ${(props) => props.background};
  &:hover {
    background-color: ${(props) => props.backgroundOnHover};
  }
  svg {
    color: ${(props) => props.iconColor};
  }
`;

const RangeSlider = styled.input`
  background: none;
  width: ${(props) => props.width};
  vertical-align: top;
  margin: auto 1rem auto 0;
  position: relative;
  height: 0.5rem;
  cursor: pointer;

  /* Chrome */
  -webkit-appearance: none;
  &::-webkit-slider-runnable-track {
    overflow: hidden;
    background: #ddd;
    height: 0.5rem;
    width: 1rem;
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 1rem;
    height: 1rem;
    background: white;
    box-shadow: -100vw 0 0 100vw ${(props) => props.prevColor};
    border: 50% solid ${(props) => props.nextColor};
  }

  /* Firefox */
  &::-moz-range-progress {
    background-color: ${(props) => props.prevColor};
  }
  &::-moz-range-track {
    background-color: ${(props) => props.nextColor};
  }
  &::-moz-range-thumb {
    height: 1rem;
    width: 1rem;
  }

  /* IE */
  &::-ms-fill-lower {
    background-color: ${(props) => props.prevColor};
  }
  &::-ms-fill-upper {
    background-color: ${(props) => props.nextColor};
  }
`;

const VolumeControls = styled.div`
  background-color: ${(props) => props.background};
  border-radius: 3rem;
  max-width: 19rem;
  display: flex;
`;

const RadioPlayer = styled.article`
  flex-grow: 1;
  flex-basis: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem 0;
`;

const RadioButtons = styled.div`
  display: flex;
  flex-wrap: nowrap;
  height: min-content;
  background: ${(props) => props.background};
  border-radius: 3rem;
  button {
    cursor: pointer;
  }
`;

function getVolumeIcon(volume, muted) {
  if (muted) {
    return faVolumeMute;
  }
  if (volume === 0) {
    return faVolumeOff;
  }
  if (volume < 0.5) {
    return faVolumeDown;
  }
  return faVolumeUp;
}

export default function Radio({
  src,
  autoPlay,
  background,
  backgroundOnHover,
  sliderPrevColor,
  sliderNextColor,
  sliderWidth,
  iconsColor,
  iconsSize,
  mute,
}) {
  const [paused, setPaused] = useState(!autoPlay);
  const [muted, setMuted] = useState(mute);
  const [volume, setVolume] = useState(1);

  useEffect(() => {
    const radio = document.getElementById('radio');
    if (autoPlay) {
      radio.play();
    } else {
      radio.pause();
    }
  }, []);

  useEffect(() => {
    if (document) {
      const radio = document.getElementById('radio');
      radio.muted = muted;
    }
  }, [muted]);

  useEffect(() => {
    if (document) {
      const radio = document.getElementById('radio');
      radio.volume = volume;
    }
  }, [volume]);

  return (
    <RadioPlayer>
      <audio id="radio">
        <source src={src} />
      </audio>
      <RadioButtons id="radio-buttons" background={background}>
        <Button
          onClick={() => {
            const radio = document.getElementById('radio');
            if (radio.paused) {
              radio.play();
            } else {
              radio.pause();
            }
            setPaused(!paused);
          }}
          type="button"
          aria-label="tocar rádio"
          aria-pressed={paused}
          aria-labelledby="radio-buttons"
          background={background}
          backgroundOnHover={backgroundOnHover}
          iconColor={iconsColor}
          size={iconsSize}
        >
          <FontAwesomeIcon icon={paused ? faPlay : faPause} />
        </Button>
        <VolumeControls background={background}>
          <Button
            onClick={() => setMuted(!muted)}
            type="button"
            aria-label="silenciar rádio"
            aria-pressed={muted}
            aria-labelledby="radio-buttons"
            background={background}
            backgroundOnHover={backgroundOnHover}
            iconColor={iconsColor}
            size={iconsSize}
          >
            <FontAwesomeIcon icon={getVolumeIcon(volume, muted)} />
          </Button>
          <RangeSlider
            type="range"
            min="0"
            max="1"
            step="0.01"
            defaultValue={volume}
            nextColor={sliderNextColor}
            prevColor={sliderPrevColor}
            onChange={(e) => setVolume(e.target.valueAsNumber)}
            width={sliderWidth}
          />
        </VolumeControls>
      </RadioButtons>
    </RadioPlayer>
  );
}

Radio.propTypes = {
  src: PropTypes.string.isRequired,
  autoPlay: PropTypes.bool,
  background: PropTypes.string,
  backgroundOnHover: PropTypes.string,
  sliderPrevColor: PropTypes.string,
  sliderNextColor: PropTypes.string,
  iconsColor: PropTypes.string,
  sliderWidth: PropTypes.string,
  iconsSize: PropTypes.string,
};

Radio.defaultProps = {
  autoPlay: false,
  background: theme.color.accent_dark,
  backgroundOnHover: theme.color.accent,
  sliderPrevColor: theme.color.primary,
  sliderNextColor: theme.color.background,
  iconsColor: theme.color.text_on_primary,
  sliderWidth: '11rem',
  iconsSize: '3rem',
};
