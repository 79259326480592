/* eslint-disable no-shadow */
import React from 'react';
import styled from 'styled-components';
import SocialLinks from '../../components/SocialLinks';
import withSocialNetworks from '../../data/social-networks';
import theme from '../../theme';

const Container = styled.section`
  background: ${({ theme }) => theme.colors.secondary_dark};
  color: ${({ theme }) => theme.colors.secondary};
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 2rem 0;

  h3 {
    flex-grow: 1;
    text-transform: none;
    color: ${({ theme }) => theme.colors.text_on_secondary_dark};
    font-weight: 700;
    font-size: 0.8rem;
    text-align: center;
    margin-bottom: 2rem;
    max-width: ${({ theme }) => theme.dimensions.mobile.min};

    @media (min-width: ${({ theme }) => theme.dimensions.mobile.min}) {
      font-size: 1rem;
      text-align: left;
    }

    @media (min-width: ${({ theme }) => theme.dimensions.mobile.max}) {
      font-size: 1.2rem;
    }
  }
`;

const ItemContainer = styled.div`
  padding: 0 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  & > div > span {
    margin: 0 0.5rem;

    &:last-of-type {
      margin: 0;
    }
  }

  & > div > a > button {
    width: 48px;
    height: 48px;
    font-size: calc(48px * 0.6);
    transition: 0.3s;

    &:hover {
      background: ${({ theme }) => theme.colors.primary_dark};
      color: ${({ theme }) => theme.colors.white};
    }
  }

  @media (min-width: ${({ theme }) => theme.dimensions.mobile.min}) {
    padding: 0 2rem;
  }
`;

const Renderer = (props) => {
  const { socialNetworks: data } = props;

  const socialNetworks = data.reduce(
    (accumulator, current) => ({
      ...accumulator,
      [current.network]: current.link,
    }),
    {},
  );

  return (
    <Container>
      <ItemContainer>
        <h3>Siga e compartilhe essa paixão!</h3>
        <SocialLinks
          networks={socialNetworks}
          background={theme.colors.white}
          color={theme.colors.secondary_dark}
          size="medium"
        />
      </ItemContainer>
    </Container>
  );
};

export default withSocialNetworks(Renderer);
