import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

const Container = styled.button`
  font-size: 1.2rem;
  font-weight: 700;
  line-height: 1.2em;
  text-transform: uppercase;
  border: none;
  background: none;
  padding: 1rem;

  svg:first-child {
    margin-right: 0.5rem;
    font-size: 1rem;
  }
`;

export default function GoBack(props) {
  const { handleClick } = props;

  return (
    <Container onClick={handleClick}>
      <FontAwesomeIcon icon={faArrowLeft} width="0" />
      Voltar
    </Container>
  );
}
