import React, { useContext, createContext, useEffect, useState } from 'react';
import { init, isAuthenticatedUser, signIn, signOut } from '../auth';

const AuthContext = createContext({
  user: null,
  isLoggedIn: false,
  login: () => {},
  logout: () => {},
  checkUserAuthenticity: () => {},
});

export function AuthProvider({ children, endpoint }) {
  const [user, setUser] = useState(null);
  const [isLoggedIn, setLoggedIn] = useState();

  init(endpoint);

  async function login(payload) {
    const userData = await signIn(payload);
    setUser(userData);
    setLoggedIn(true);
    return true;
  }

  function logout() {
    signOut();
    setUser(null);
    setLoggedIn(false);
  }

  async function checkUserAuthenticity(token = user.token) {
    try {
      await isAuthenticatedUser(token);
      setLoggedIn(true);
      return true;
    } catch (error) {
      setUser(null);
      setLoggedIn(false);
      return false;
    }
  }

  useEffect(() => {
    const verifiyAuthenticity = async (data) => {
      if (!data) {
        setLoggedIn(false);
        return;
      }
      const authenticated = await checkUserAuthenticity(data.token);
      if (authenticated) {
        setUser(data);
        setLoggedIn(true);
      } else {
        setLoggedIn(false);
      }
    };

    if (!user) {
      const storageUser = JSON.parse(localStorage.getItem('sci-partner'));

      /* eslint no-unused-expressions: ["error", { "allowShortCircuit": true }] */
      verifiyAuthenticity(storageUser);
    }
  }, []);

  return (
    <AuthContext.Provider
      value={{
        user,
        isLoggedIn,
        login,
        logout,
        checkUserAuthenticity,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export const useAuth = () => useContext(AuthContext);
