/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
require('./src/styles/wp-styles.css');
require('./src/styles/wp-theme.css');
require('./src/styles/wp-blocks.css');
require('./src/styles/wp-custom.css');
require('swiper/css/swiper.css');
