import colors from './colors';

export default {
  color: {
    primary: colors.primary,
    primary_dark: colors.primary_dark,
    accent: colors.accent,
    accent_dark: colors.secondary_dark,
    color: colors.black,
    black: colors.black,
    text_on_primary: colors.text_on_primary,
    text_on_secundary: colors.text_on_secondary,
    warning: '#F23A00',
  },
  background: '#e5e5e5',
  highlight: {
    background: '#f7b500',
    color: '#232323',
  },
  main: {
    color: '#232323',
    background: '#ffffff',
  },
  footer: {
    background: '#f3f3f3',
    color: '#9d9d9d',
  },
  sponsors: {
    color: '#9d9d9d',
    background: '#dcdcdc',
    border_color: '#ffffff',
  },
  sitemap: {
    color: '#979797',
    background: '#575756',
    social_color: '#dcdcdc',
  },
  articles: {
    header: {
      max_width: '55.5rem',
    },
    content: {
      max_width: '55.5rem',
    },
  },
  directors: {
    background: '#dcdcdc',
  },
  matchesWidget: {
    background: '#86000F',
    bottomDetail: '#B30A19',
  },
  width: {
    header: '1280px',
    main: '1600px',
    content_header: '55.5rem',
    content: '42.5rem',
    footer: '1280px',
    mobile: '1024px',
  },
  colors,
  radio: {
    sliderPrevColor: '#f7b500',
    sliderNextColor: colors.secondary_dark,
  },
  dimensions: {
    desktop: {
      header: '1280px',
      main: '1360px',
      footer: '1280px',
      content: '1280px',
    },
    mobile: {
      max: '1024px',
      med: '568px',
      min: '320px',
    },
  },
  breakpoints: ['320px', '568px', '1024px', '1920px'],
  text: {
    size: {
      default: '16',
      min: '13',
      max: '16',
      h1: {
        min: '32',
        max: '48',
      },
      h2: {
        min: '24',
        max: '36',
      },
      h3: {
        min: '20',
        max: '28',
      },
      h4: {
        min: '16',
        max: '24',
      },
      menu: {
        min: '13',
        max: '16',
      },
      search: {
        min: '16',
        max: '64',
      },
    },
    calcGenerically: (min, max) =>
      `calc(${min}px + (${max} - ${min}) * ((100vw - 320px) / (1920 - 320)))`,
    getSize(type) {
      let min;
      let max;
      switch (type) {
        case 'h1':
        case 'page-title':
          min = this.size.h1.min;
          max = this.size.h1.max;
          break;
        case 'cards-title':
        case 'highlights':
        case 'h2':
          min = this.size.h2.min;
          max = this.size.h2.max;
          break;
        case 'menu-title':
        case 'news':
        case 'h3':
          min = this.size.h3.min;
          max = this.size.h3.max;
          break;
        case 'h4':
          min = this.size.h4.min;
          max = this.size.h4.max;
          break;
        case 'menu':
          min = this.size.menu.min;
          max = this.size.menu.max;
          break;
        case 'search':
          min = this.size.search.min;
          max = this.size.search.max;
          break;
        default:
          min = this.size.default.min;
          max = this.size.default.max;
      }
      return `calc(${min}px + (${max} - ${min}) * ((100vw - 320px) / (1920 - 320)))`;
    },
  },
};
