import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Link as InternalLink } from 'gatsby';
import { faUser } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import RadioPlayer from '../../components/Radio';
import withRadio from '../../data/radio';
import withHeaderSettings from '../../data/header';
import theme from '../../theme';
import { useAuth } from '../../context';
import { faYoutube, faYoutubeSquare } from '@fortawesome/free-brands-svg-icons';

const Link = styled.a`
  display: flex;
  align-items: center;
  font-size: 1.5rem;

  @media (min-width: ${theme.dimensions.mobile.max}) {
    margin-left: 1rem;
    font-size: 1.2rem;
  }

  span {
    padding: 0 12px;
    font-size: 1rem;
    display: none;

    @media (min-width: ${theme.dimensions.mobile.max}) {
      display: unset;
    }
  }
`;

function MemberAreaRender(props) {
  const { memberLink } = props;

  return (
    <Link href={memberLink} data-test-id="link-area-socio">
      <FontAwesomeIcon icon={faUser} width="0" />
      <span>Área do sócio</span>
    </Link>
  );
}

export const MemberArea = withHeaderSettings(MemberAreaRender);

const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  flex: 1 0 40%;
`;

const RadioArea = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: 100%;

  .topbar-radio-player {
    display: none;

    @media (min-width: ${theme.dimensions.mobile.max}) {
      grid-gap: 0.5rem;
      display: unset;
    }
  }
`;

const RadioLinkContainer = styled.a`
  display: flex;
  align-items: center;
  font-size: 1.75rem;

  @media (min-width: ${theme.dimensions.mobile.max}) {
    font-size: 1.2rem;
  }

  span {
    padding: 0 12px;
    font-size: 1rem;
    display: none;

    @media (min-width: ${theme.dimensions.mobile.max}) {
      display: unset;
    }
  }
`;

export function RadioLink() {
  return (
    <RadioLinkContainer
      href="http://www.youtube.com/@scinternacional"
      rel="noopener noreferrer"
      target="_blank"
    >
      <FontAwesomeIcon icon={faYoutube} width="0" />
      <span>Transmissões</span>
    </RadioLinkContainer>
  );
}

function RadioRender(props) {
  const { radioLink } = props;
  return (
    <RadioArea>
      <RadioLink />
    </RadioArea>
  );
}

export const Radio = withRadio(RadioRender);

// const CounterContainer = styled.div`
//   margin: 0px 2rem;
// `;

// function Counter({ counter }) {
//   return (
//     <CounterContainer>
//       <CounterHeader counter={counter} />
//     </CounterContainer>
//   );
// }

const LogoutButton = styled(Link).attrs((props) => ({
  as: 'button',
  ...props,
}))`
  background: none;
  cursor: pointer;
  border: none;
  color: white;
  display: ${({ hide }) => (hide ? 'none' : 'auto')};
`;

const MembershipLink = styled.a`
  text-decoration: none;
  width: 10rem;
`;

const SearchWrapper = styled.div`
  margin-inline: 1rem;
`;

export function Logout({ ariaLabel }) {
  const { logout, isLoggedIn } = useAuth();
  return (
    <LogoutButton onClick={logout} aria-label={ariaLabel} hide={!isLoggedIn}>
      <FontAwesomeIcon icon={faSignOutAlt} width="0" />
      <span>Sair</span>
    </LogoutButton>
  );
}

export default function Topbar() {
  const [renderRadioPlayer, setRenderRadioPlayer] = useState(false);
  useEffect(() => {
    setRenderRadioPlayer(
      window.innerWidth >=
        Number(theme.dimensions.desktop.header.replace('px', '')),
    );
  }, []);
  return (
    <Container>
      <SearchWrapper>
        <InternalLink to="/pesquisa" aria-label="pesquisa">
          <FontAwesomeIcon icon={faSearch} />
        </InternalLink>
      </SearchWrapper>
      {renderRadioPlayer && <Radio />}
      <Logout />
    </Container>
  );
}
