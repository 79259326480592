import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Swiper from 'react-id-swiper';

const Container = styled.div`
  z-index: 10;
  background: ${({ theme }) =>
    `linear-gradient(180deg, ${theme.colors.secondary_dark} 0%, ${theme.colors.black} 100%)`};
  width: 100%;
  transition: 0.6s;
  position: fixed;
  top: ${({ collapsed }) => (collapsed ? '100px' : '140px')};
  height: auto;
  overflow: hidden;
  max-height: ${({ opened }) => (opened ? '50%' : '0')};
`;

const Inner = styled.div`
  max-width: ${({ theme }) => theme.dimensions.desktop.header};
  width: 100%;
  margin: 0 auto;
`;

function MenuSwiper(props) {
  const { index, getSwiper, children } = props;
  const [swiper, updateSwiper] = useState(null);

  useEffect(() => {
    if (swiper === null) {
      return;
    }
    if (typeof getSwiper === 'function') {
      getSwiper({
        slideTo: (index) => swiper.slideTo(index, 800),
      });
    }
    swiper.slideTo(index, 0);
  }, [index, swiper]);

  return <Swiper getSwiper={updateSwiper}>{children}</Swiper>;
}

export default function MegaMenu(props) {
  const { getMenu, children, collapsed, onMouseLeave } = props;

  const [state, setState] = useState({ opened: false, index: 0 });
  const [swiper, updateSwiper] = useState(null);

  useEffect(() => {
    if (typeof getMenu === 'function') {
      getMenu({
        ...state,
        open: (index = 0) => setState({ opened: true, index }),
        close: () => setState({ opened: false, index: state.index }),
        show: (index) => swiper?.slideTo(index),
      });
    }
  }, [state]);

  return (
    <Container
      opened={state.opened}
      collapsed={collapsed}
      onMouseLeave={onMouseLeave}
    >
      <Inner>
        <MenuSwiper index={state.index} getSwiper={updateSwiper}>
          {children}
        </MenuSwiper>
      </Inner>
    </Container>
  );
}
