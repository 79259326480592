import axios from 'axios';

let authInstance = axios.create({
  baseURL: '', // 'https://mclqxeo7hk.execute-api.us-east-1.amazonaws.com/homolog', // process.env.SOCIETY_AUTH_ENDPOINT,
});

export function connect(endpoint) {
  authInstance = axios.create({
    baseURL: endpoint, // process.env.SOCIETY_AUTH_ENDPOINT,
  });
}

export async function login(username, password) {
  return authInstance.post('login', { username, password });
}

export async function checkTokenValidity(token) {
  return authInstance.get(`tokens/${token}`);
}
