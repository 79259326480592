import React from 'react';
import { graphql, StaticQuery } from 'gatsby';

export default function withRadio(Component) {
  const query = graphql`
    query GET_RADIO {
      wpgraphql {
        generalConfig(id: "footer", idType: SLUG) {
          footerData {
            radioLogo {
              sourceUrl
            }
            radioLink
          }
        }
      }
    }
  `;
  const render = (data) => {
    const {
      wpgraphql: {
        generalConfig: { footerData },
      },
    } = data;
    const { radioLogo, radioLink } = footerData;

    return <Component radioLogo={radioLogo.sourceUrl} radioLink={radioLink} />;
  };

  return function HOC() {
    return <StaticQuery query={query} render={render} />;
  };
}
