import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faSearch } from '@fortawesome/free-solid-svg-icons';
import withHeaderSettings from '../../data/header';
import CallToAction from '../../components/call-to-action';
import Topbar, { Radio, MemberArea, Searchable, Logout } from './topbar';
import MegaMenu from './mega-menu';
import MobileMenu from './mobile-menu';
import MundoColorado from '../../images/mundo-colorado-branco.png';
// import CounterHeader from '../../components/CounterHeader';

import sitemap, { renderNav, renderMenu, MobileRenderer } from '../sitemap';
import Texture from '../../images/asfalt-light.png';
import theme from '../../theme';

const Container = styled.header`
  position: fixed;
  top: 0;
  z-index: 1111;
  width: 100vw;
  padding: 0;
  margin: 0 auto;
  height: 70px;
  transition: height 0.4s;
  /* This is mostly intended for prototyping; please download the pattern and re-host for production environments. Thank you! */
  background-color: ${({ theme }) => theme.colors.primary_dark};
  background-image: url(${Texture});

  color: ${({ theme }) => theme.colors.text_on_primary_dark};

  @media (min-width: ${({ theme }) => theme.dimensions.mobile.max}) {
    height: 140px;

    /* position: relative; */
    &.collapsed {
      height: 100px;
    }
    &.transparent {
      background: transparent;
    }
  }
`;

const Inner = styled.div`
  width: 100%;
  max-width: ${({ theme }) => theme.dimensions.desktop.header};
  height: 100%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: auto max-content max-content max-content max-content max-content max-content;
  grid-template-rows: 100%;
  grid-template-areas: 'logo counter search radio members logout navbar';

  @media (min-width: ${({ theme }) => theme.dimensions.mobile.max}) {
    grid-template-columns: 260px auto 150px;
    grid-template-rows: 40% 60%;
    grid-template-areas: 'logo topbar topbar' 'logo navbar cta';
  }
`;

const Logo = styled.div`
  grid-area: logo;
  background-image: url(${({ logo }) => logo});
  background-position-y: center;
  background-position-x: left;
  background-size: contain;
  background-repeat: no-repeat;
  overflow: hidden;
  margin: 0.5rem 0.5rem;
  position: relative;

  @media (min-width: ${({ theme }) => theme.dimensions.mobile.max}) {
    background-image: url(${({ signature }) => signature});
  }

  a {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: transparent;
  }
`;

const TopbarArea = styled.div`
  display: none;

  @media (min-width: ${({ theme }) => theme.dimensions.mobile.max}) {
    grid-area: topbar;
    display: flex;
  }
`;

const Navbar = styled.div`
  grid-area: navbar;
  padding: 0 1rem;
  display: none;
  justify-content: space-evenly;
  align-items: center;
  font-weight: 700;
  text-transform: uppercase;

  & > span {
    cursor: default;
  }

  @supports (-ms-ime-align: auto) {
    & {
      justify-content: space-around;
    }
  }

  @media (min-width: ${({ theme }) => theme.dimensions.mobile.max}) {
    display: flex;
  }
`;

const MenuButton = styled.a`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  grid-area: navbar;
  font-size: 2rem;
  margin: 1rem;
  cursor: pointer;

  @media (min-width: ${({ theme }) => theme.dimensions.mobile.max}) {
    display: none;
  }
`;

const CTA = styled.div`
  display: none;

  @media (min-width: ${({ theme }) => theme.dimensions.mobile.max}) {
    display: block;
    grid-area: cta;
    justify-self: center;
    align-self: center;
    margin-right: 1rem;
  }
`;

const RadioArea = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  grid-area: radio;
  margin: 0px 0.5rem;

  @media (min-width: ${({ theme }) => theme.dimensions.mobile.max}) {
    display: none;
  }
`;

const SearchArea = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  grid-area: search;
  font-size: 1.5rem;
  margin: 0px 0.5rem;

  @media (min-width: ${({ theme }) => theme.dimensions.mobile.max}) {
    display: none;
  }
`;

const MemberContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  grid-area: members;
  font-size: 1.5rem;
  margin: 0px 0.5rem;

  @media (min-width: ${({ theme }) => theme.dimensions.mobile.max}) {
    display: none;
  }
`;

const MobileContainer = styled.div`
  padding: 1rem;
`;

const MembershipLink = styled.a`
  text-decoration: none;
`;

const LogoutContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  margin: 0px 0.5rem;
  grid-area: logout;
  @media (min-width: ${({ theme }) => theme.dimensions.mobile.max}) {
    display: none;
  }
`;

const CounterArea = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  grid-area: counter;

  @media (min-width: ${({ theme }) => theme.dimensions.mobile.max}) {
    display: none;
  }
`;

function Header(props) {
  const { logo, signature, portalSocioEndpoint } = props;

  const [collapsed, setCollapsed] = useState(false);
  const [menu, updateMenu] = useState(null);
  const [mobileMenu, updateMobileMenu] = useState(null);
  const [submenu, updateSubmenu] = useState(null);
  const menuIndexes = Object.keys(sitemap).filter(
    (index) => sitemap[index].menu,
  );

  const [renderRadioPlayer, setRenderRadioPlayer] = useState(false);
  useEffect(() => {
    setRenderRadioPlayer(
      window.innerWidth <
        Number(theme.dimensions.desktop.header.replace('px', '')),
    );
  }, []);

  const handleScroll = () => {
    if (typeof window !== 'undefined' && window.scrollY > 50) {
      setCollapsed(true);
    } else {
      setCollapsed(false);
    }
  };

  useEffect(() => {
    // handleScroll();

    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', handleScroll);
    }
    return () => {
      if (typeof window !== 'undefined') {
        window.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  function handleMenu(index) {
    if (menu === null) {
      return null;
    }
    if (index === null) {
      return menu.close();
    }
    if (menu.opened === false) {
      return menu.open(index);
    }
    return menu.show(index);
  }

  function toggleMobileMenu() {
    if (submenu) {
      submenu.reset();
    }
    if (mobileMenu) {
      mobileMenu.toggle();
    }
    return null;
  }

  // const [counter, setCounter] = useState(null);

  // useEffect(() => {
  //   async function getCounter() {
  //     const response = await fetch(`${groundAPI}/members-counter.json`);
  //     if (response.ok) {
  //       const counterData = await response.json();
  //       setCounter(counterData.counter);
  //     } else {
  //       console.error('Could not get members counter');
  //     }
  //   }

  //   getCounter();
  // }, []);

  return (
    <Container className={collapsed ? 'collapsed' : ''} disabled>
      <MegaMenu
        getMenu={updateMenu}
        collapsed={collapsed}
        onMouseLeave={() => handleMenu(null)}
      >
        {menuIndexes.map((key) =>
          renderMenu(
            sitemap[key].menu,
            key,
            sitemap[key].inline,
            sitemap[key].label,
            () => handleMenu(null),
          ),
        )}
      </MegaMenu>
      <MobileMenu getMenu={updateMobileMenu}>
        <MobileContainer>
          {/* <MemberArea /> */}
          <MobileRenderer
            handleClick={toggleMobileMenu}
            getMenu={updateSubmenu}
          />
          <MembershipLink
            href={portalSocioEndpoint}
            aria-label="Acesse a area do Sócio"
          >
            <img src={MundoColorado} alt="" />
          </MembershipLink>
        </MobileContainer>
      </MobileMenu>
      <Inner>
        <Logo logo={logo} signature={signature}>
          <Link to="/" onMouseEnter={() => handleMenu(null)} />
        </Logo>
        {/* <CounterArea>
          <CounterHeader counter={counter} mobile />
        </CounterArea> */}
        <TopbarArea onMouseEnter={() => handleMenu(null)}>
          <Topbar />
        </TopbarArea>
        <Navbar>{renderNav(handleMenu)}</Navbar>
        <MenuButton onClick={toggleMobileMenu} role="button" aria-label="Menu">
          <FontAwesomeIcon icon={faBars} width="0" />
        </MenuButton>
        <CTA>
          <MembershipLink
            href={portalSocioEndpoint}
            aria-label="Acesse a area do Sócio"
          >
            <img src={MundoColorado} alt="" />
          </MembershipLink>
        </CTA>
        {renderRadioPlayer && (
          <RadioArea>
            <Radio />
          </RadioArea>
        )}
        <LogoutContainer>
          <Logout ariaLabel="Logout" />
        </LogoutContainer>
      </Inner>
    </Container>
  );
}

Header.propTypes = {
  logo: PropTypes.string.isRequired,
  signature: PropTypes.string.isRequired,
};

export default withHeaderSettings(Header);
