import React from 'react';
import { graphql, StaticQuery } from 'gatsby';

export default function withSocialNetworks(Component) {
  const query = graphql`
    query GET_SOCIAL {
      wpgraphql {
        generalConfig(id: "footer", idType: SLUG) {
          footerData {
            socialNetworks {
              link
              network
            }
          }
        }
      }
    }
  `;
  const render = (data) => {
    const {
      wpgraphql: {
        generalConfig: { footerData },
      },
    } = data;
    const { socialNetworks } = footerData;

    return <Component socialNetworks={socialNetworks} />;
  };

  return function HOC() {
    return <StaticQuery query={query} render={render} />;
  };
}
