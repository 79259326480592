import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const Container = styled.div`
  background: rgba(0, 0, 0, 0.9);
  position: fixed;
  top: 0;
  right: ${({ opened }) => (opened ? '0' : '-100%')};
  z-index: 10;
  transition: 0.6s;
  height: 100%;
  width: 100%;
`;

const CloseContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const CloseButton = styled.a`
  font-size: 2rem;
  cursor: pointer;
  margin: 1rem;
  line-height: 1em;
`;

export default function MobileMenu(props) {
  const { getMenu, children } = props;
  const [opened, toggle] = useState(false);

  useEffect(() => {
    if (typeof getMenu === 'function') {
      getMenu({
        opened,
        open: () => toggle(true),
        close: () => toggle(false),
        toggle: () => toggle(!opened),
      });
    }
  }, [opened]);

  return (
    <Container opened={opened}>
      <CloseContainer>
        <CloseButton
          role="button"
          aria-label="Fechar"
          onClick={() => toggle(false)}
        >
          <FontAwesomeIcon icon={faTimes} width="0" />
        </CloseButton>
      </CloseContainer>
      {children}
    </Container>
  );
}
