import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Button = styled.a`
  align-items: center;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5rem;
  background-color: ${({ theme }) => theme.highlight.background};
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  color: ${({ theme }) => theme.highlight.color};
  text-transform: uppercase;
  border-radius: 2px;
  padding: 0 1rem;
  min-height: 36px;
  min-width: 64px;
  display: flex;
  justify-content: center;
  align-content: center;
  white-space: nowrap;
`;

export default function CallToAction(props) {
  const { to, label, ariaLabel } = props;

  return (
    <Button
      target="_blank"
      href={to}
      onClick={(e) => e.stopPropagation()}
      data-test-id="btn-call-to-action"
      aria-label={ariaLabel}
    >
      {label}
    </Button>
  );
}

CallToAction.propTypes = {
  to: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};
