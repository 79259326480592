import React from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';
import Header from './Header';
import Footer from './Footer';
import { AuthProvider } from '../context';

const Main = styled.main`
  width: 100%;
  margin: 0 auto;
  padding: 70px 0 0;
  overflow-x: hidden;

  @media (min-width: ${({ theme }) => theme.dimensions.mobile.max}) {
    padding: 140px 0 0;
  }
  [aria-disabled='true'] {
    pointer-events: none;
  }
`;

export default function Page({ children }) {
  const {
    site: {
      siteMetadata: { societyAuthEndpoint },
    },
  } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            societyAuthEndpoint
          }
        }
      }
    `,
  );

  return (
    <AuthProvider endpoint={societyAuthEndpoint}>
      <Header />
      <Main>{children}</Main>
      <Footer />
    </AuthProvider>
  );
}
