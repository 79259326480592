import React from 'react';
import { graphql, StaticQuery } from 'gatsby';

export default function withHeaderSettings(Component) {
  const query = graphql`
    query GET_HEADER {
      site {
        siteMetadata {
          groundAPI
          portalSocioEndpoint
        }
      }
      wpgraphql {
        generalConfig(id: "header", idType: SLUG) {
          headerData {
            ctaLabel
            ctaLink
            memberAreaLink
            shopLink
            logo {
              sourceUrl
            }
            signature {
              sourceUrl
            }
          }
        }
      }
    }
  `;
  const render = (data) => {
    const {
      wpgraphql: {
        generalConfig: { headerData },
      },
      site: {
        siteMetadata: { groundAPI, portalSocioEndpoint },
      },
    } = data;
    const {
      logo,
      signature,
      ctaLabel,
      ctaLink,
      memberAreaLink,
      shopLink,
    } = headerData;

    return (
      <Component
        logo={logo.sourceUrl}
        signature={signature.sourceUrl}
        CTALabel={ctaLabel}
        CTALink={ctaLink}
        memberLink={memberAreaLink}
        shopLink={shopLink}
        groundAPI={groundAPI}
        portalSocioEndpoint={portalSocioEndpoint}
      />
    );
  };

  return function HOC() {
    return <StaticQuery query={query} render={render} />;
  };
}
