/* eslint-disable react/default-props-match-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter } from '@fortawesome/free-brands-svg-icons';

import { a11yButtonProps, testProps } from './helpers';
import CircularButton from './styles';

export default function Twitter(props) {
  const { url, size, color, background, ariaLabel, dataTestID } = props;

  return (
    <a href={url} target="_blank" rel="noopener noreferrer">
      <CircularButton
        size={size}
        color={color}
        background={background}
        ariaLabel={ariaLabel}
        dataTestID={dataTestID}
      >
        <FontAwesomeIcon icon={faTwitter} width="0" />
      </CircularButton>
    </a>
  );
}

Twitter.propTypes = {
  url: PropTypes.string.isRequired,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  color: PropTypes.string,
  background: PropTypes.string,
  ...a11yButtonProps,
  ...testProps,
};

Twitter.defaultProps = {
  size: 'medium',
  color: '#fff',
  background: '#1da1f2',
  ariaLabel: 'Ir para Facebook',
  dataTestID: 'facebook-link',
};
