import { createGlobalStyle } from 'styled-components';
import '@fortawesome/fontawesome-svg-core/styles.css';
import { config } from '@fortawesome/fontawesome-svg-core';
import '../../node_modules/video-react/dist/video-react.css';

config.autoAddCss = false;

const GlobalStyle = createGlobalStyle`
  * {
    padding: 0;
    margin: 0;
    outline-color: transparent;
  }

  body {
    background-color: ${({ theme }) => theme.background};
    text-rendering: optimizeLegibility;
    font-family: 'Roboto', sans-serif;
    font-size: ${({ theme }) => theme.text.getSize()};
    min-width: 320px;
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  ul {
    list-style: none;
  }

  figure, img {
    margin: 0;
    padding: 0;
  }
`;

export default GlobalStyle;
