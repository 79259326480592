/* eslint-disable no-param-reassign */
import React from 'react';
import moment from 'moment';

export function decodeHtml(html) {
  return `${html}`.replace(/&#\d+;/gm, (s) =>
    String.fromCharCode(s.match(/\d+/gm)[0]),
  );
}

export function removeHtmlTags(text) {
  return text.replace(/<(.|\n)*?>/g, '');
}

export function wrapMultiline(text, WrapperComponent) {
  const splitted = text.split(/\r\n|\r|\n/);

  return splitted.map((str) => <WrapperComponent>{str}</WrapperComponent>);
}

function getPositionOrRoleOfPlayer(player) {
  return player.papel.label.toLowerCase() === 'atleta'
    ? player.posicao.label
    : player.papel.label;
}

function addToPlayers(player, extraFields) {
  const { slug, title } = extraFields;
  const newPlayer = player;
  newPlayer.title = title;
  newPlayer.slug = player.papel.label.toLowerCase() === 'atleta' ? slug : null;
  return newPlayer;
}

export function teamMembersWithinRoles(players) {
  return players.reduce((accumulator, current) => {
    if (accumulator.acf) {
      const playerAcf = accumulator.acf;
      const player = addToPlayers(playerAcf, {
        slug: accumulator.slug,
        title: accumulator.title,
      });
      const playerPositionOrRole = getPositionOrRoleOfPlayer(player);
      if (playerPositionOrRole === getPositionOrRoleOfPlayer(current.acf)) {
        return { [player.posicao.label]: [player, current.acf] };
      }
      return {
        [player.posicao.label]: [player],
        [current.acf.posicao.label]: [current.acf],
      };
    }
    const playerAcf = current.acf;
    const player = addToPlayers(playerAcf, {
      slug: current.slug,
      title: current.title,
    });
    const playerPositionOrRole = getPositionOrRoleOfPlayer(player);
    if (!accumulator[playerPositionOrRole]) {
      return { ...accumulator, [playerPositionOrRole]: [player] };
    }
    return {
      ...accumulator,
      [playerPositionOrRole]: [...accumulator[playerPositionOrRole], player],
    };
  }, {});
}

export function convertPositionNounGender(position, elenco = '') {
  let convertedPosition = position;
  if (elenco.toLowerCase() === 'futebol feminino') {
    convertedPosition = position.replace(/o$/, 'a');
    convertedPosition = convertedPosition.replace(/or$/, 'ar');
  }
  return convertedPosition;
}

export function weekday(weekdaySimplified, date) {
  const weekdaySimplifiedUpperCased = weekdaySimplified.toUpperCase();
  const weekdays = {
    DOM: 'Domingo',
    SEG: 'Segunda',
    TER: 'Terça',
    QUA: 'Quarta',
    QUI: 'Quinta',
    SEX: 'Sexta',
    SÁB: 'Sábado',
    0: 'Domingo',
    1: 'Segunda',
    2: 'Terça',
    3: 'Quarta',
    4: 'Quinta',
    5: 'Sexta',
    6: 'Sábado',
  };
  const result = weekdays[weekdaySimplifiedUpperCased];
  if (result) {
    return result;
  }
  const [day, month, year] = date.split('/');
  const momentDate = moment(`${year}-${month}-${day}`);
  const weekdayCode = momentDate.weekday();
  return weekdays[weekdayCode];
}

export function capitalize(str) {
  if (typeof str === 'string') {
    return str.substring(0, 1).toUpperCase() + str.substring(1);
  }
  return str;
}

export function sortPlayers(players) {
  const sorted = players.sort((a, b) => {
    if (a.position.value === b.position.value) {
      return a.order - b.order;
    }
    return a.position.value - b.position.value;
  });
  return sorted;
}

export function sortCollaborators(collaborators) {
  const sorted = collaborators.sort((a, b) => {
    if (
      a.playerData.cargo === 'Diretor-executivo' &&
      b.playerData.cargo === 'Coordenador Técnico'
    ) {
      return -1;
    }
    if (
      a.playerData.cargo === 'Coordenador Técnico' &&
      b.playerData.cargo === 'Diretor-executivo'
    ) {
      return 1;
    }
    if (
      a.playerData.cargo === 'Diretor-executivo' ||
      a.playerData.cargo === 'Coordenador Técnico'
    ) {
      return -100;
    }
    if (
      b.playerData.cargo === 'Diretor-executivo' ||
      b.playerData.cargo === 'Coordenador Técnico'
    ) {
      return 100;
    }
    if (a.role.value === b.role.value) {
      return a.order - b.order;
    }
    return a.role.value - b.role.value;
  });
  return sorted;
}

export function isAfter(date, time, extraCall) {
  const [year, month, day] = date.split('/');
  const [hour, minute] = time.split(':');
  const currentMoment = moment();
  const informedMoment = moment(`${year}-${month}-${day}-${hour}-${minute}`);
  if (extraCall) {
    const { method, params } = extraCall;
    return currentMoment.isAfter(informedMoment[method](...params));
  }
  return moment().isAfter(moment(`${year}-${month}-${day}-${hour}-${minute}`));
}
