import { a11yButtonProps, testProps } from './accessibility';

const socialNetworkUserURL = {
  facebook: 'https://www.facebook.com',
  flickr: 'https://www.flickr.com/photos',
  instagram: 'https://www.instagram.com',
  soundcloud: 'https://soundcloud.com',
  spotify: 'https://open.spotify.com/user',
  twitter: 'https://twitter.com',
  youtube: 'https://www.youtube.com/user',
  onefootball: 'https://onefootball.com/pt-br/time',
};

function getSocialNetworkURL(socialNetwork, user) {
  const socialNetworkDownCased = socialNetwork.toLowerCase();
  const socialNetworkBaseURL = socialNetworkUserURL[socialNetworkDownCased];
  const notFound = !socialNetworkBaseURL;
  if (notFound) {
    return user;
  }
  const userWithoutAtURI = user.replace(/.{0}[@]*/, '');
  if (user.includes(socialNetworkDownCased)) {
    return user;
  }
  return `${socialNetworkBaseURL}/${userWithoutAtURI}`;
}

export { getSocialNetworkURL, testProps, a11yButtonProps };
