import React from 'react';
import PropTypes from 'prop-types';

function ConditionalRenderer({
  condition,
  renderOnSuccess,
  renderOnFailure = () => null,
}) {
  return condition ? renderOnSuccess() : renderOnFailure();
}

export default React.memo(ConditionalRenderer);

PropTypes.propTypes = {
  condition: PropTypes.oneOf([PropTypes.object, PropTypes.bool]),
  renderOnSuccess: PropTypes.func.isRequired,
  renderOnFailure: PropTypes.func,
};
